@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
    /* background-color: white !important; */
    background-color: #f1f3f5 !important;
    /* font-family: 'Roboto', sans-serif; */
    font-family: unset !important;
    transition: all 500ms ease;
}

/* Style the vertical scrollbar */
.specific-css-table .ant-table-body::-webkit-scrollbar {
    width: 3.5px !important;
    height: 4.5px !important;
    border-radius: 4px !important;
    background-color: rgba(55, 184, 60, 0) !important;
}

.specific-css-table .ant-table-body::-webkit-scrollbar-thumb {
    background: rgb(92, 93, 93) !important;
    border-radius: 10px !important;
    width: 2px !important;
}

/* Optionally, you can style the scrollbar track */
.specific-css-table .ant-table-body::-webkit-scrollbar-track {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
    width: 3.5px !important;
    background-color: transparent !important;
}

/* Override Ant Design table styles */
.specific-css-table .ant-table {
    background-color: transparent !important;
    color: white !important;
}

/* Override Ant Design table header styles */
.specific-css-table .ant-table-thead>tr>th {
    background-color: transparent !important;
    color: white !important;
    border-left: none !important;
    border-right: none !important;
    font-size: 1.5em !important;
    font-weight: bolder !important;
    border-bottom: none !important;
}

/* Override Ant Design table row styles */
.specific-css-table .ant-table-tbody>tr>td {
    background-color: transparent !important;
    color: white !important;
    border-bottom: none !important;
}

/* Optional: Add hover effect for better visibility */
.specific-css-table .ant-table-tbody>tr:hover {
    background-color: rgba(255,
            255,
            255,
            0.1) !important;
    /* Change the alpha value for desired opacity */
}

.specific-css-table .ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent !important;
}

.login_button {
    padding: 0px 20px 0px 20px !important;
    background: rgba(92, 93, 93, 1) !important;
    color: white !important;
    border: none !important;
    height: 50px !important;
    margin-top: 10px !important;
    border-radius: 6 !important;
    width: 100%
}

.login_button:hover {
    opacity: 0.9 !important;
}


.upload_color>.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-text .ant-upload-list-item-name {
    color: #ec671b;
}

html {
    --antd-wave-shadow-color: none !important;
}

/* Tournament Graph */

.playoff-table * {
    box-sizing: border-box;
}

.playoff-table {
    font-family: sans-serif;
    font-size: 15px;
    line-height: 1.42857143;
    font-weight: 400;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #f5f5f5;
}

.playoff-table .playoff-table-content {
    display: -ms-flex;
    display: flex;
    padding: 20px;
}

.playoff-table .playoff-table-tour {
    display: -ms-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    flex-direction: column;
    -ms-justify-content: space-around;
    justify-content: space-around;
    position: relative;
}

.playoff-table .playoff-table-pair {
    position: relative;
}

.playoff-table .playoff-table-pair:before {
    content: '';
    position: absolute;
    top: 27px;
    right: -12px;
    width: 12px;
    height: 1px;
    background-color: red;
}

.playoff-table .playoff-table-pair-winner:before {
    content: '';
    position: absolute;
    top: 27px;
    right: -12px;
    width: 12px;
    height: 1px;
}

.playoff-table .playoff-table-pair:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 1000px;
    background-color: #f5f5f5;
    right: -12px;
    z-index: 1;
}

.playoff-table .playoff-table-pair:nth-child(even):after {
    top: 28px;
}

.playoff-table .playoff-table-pair:nth-child(odd):after {
    bottom: 28px;
}

.playoff-table .playoff-table-pair-style {
    border: 1px solid #cccccc;
    background-color: white;
    width: 160px;
    margin-bottom: 20px;
}

.playoff-table .playoff-table-group {
    padding-right: 11px;
    padding-left: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: -ms-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    flex-direction: column;
    -ms-justify-content: space-around;
    justify-content: space-around;
}

.playoff-table .playoff-table-group .playoff-table-pair-style:last-child {
    margin-bottom: 0px;
}

.playoff-table .playoff-table-group:after {
    content: '';
    position: absolute;
    top: 28px;
    bottom: 29px;
    right: 0px;
    width: 1px;
    background-color: red;
}

.playoff-table .playoff-table-group:last-child {
    margin-bottom: 0;
}

.playoff-table .playoff-table-left-player,
.playoff-table .playoff-table-right-player {
    min-height: 26px;
    padding: 3px 5px;
}

.box-ppp {
    border: 2px dotted #E39161;
    width: 50%;
    padding: 10px;
}

.playoff-table .playoff-table-left-player {
    border-bottom: 1px solid #cccccc;
}

.playoff-table .playoff-table-left-player:before {
    content: '';
    position: absolute;
    bottom: 27px;
    left: -12px;
    width: 12px;
    height: 1px;
    background-color: red;
}

.playoff-table .playoff-table-left-player-winner:before {
    content: '';
    position: absolute;
    bottom: 27px;
    left: -12px;
    width: 12px;
    height: 1px;
    /* background-color: red; */
}

.playoff-table .playoff-table-right-player {
    margin-top: -1px;
    border-top: 1px solid #cccccc;
}

.playoff-table .playoff-table-third-place {
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 100px;
}

.playoff-table .playoff-table-third-place .playoff-table-left-player:before {
    display: none;
}

.playoff-table .playoff-table-tour:first-child .playoff-table-group {
    padding-left: 0;
}

.playoff-table .playoff-table-tour:first-child .playoff-table-left-player:before {
    display: none;
}

.playoff-table .playoff-table-tour:last-child .playoff-table-group:after {
    display: none;
}

.playoff-table .playoff-table-tour:last-child .playoff-table-pair:after,
.playoff-table .playoff-table-tour:last-child .playoff-table-pair:before {
    display: none;
}


/* winner */

.playoff-table .playoff-table-tour {
    display: -ms-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    flex-direction: column;
    -ms-justify-content: space-around;
    justify-content: space-around;
    position: relative;
}

.playoff-table .playoff-table-pair-winner {
    position: relative;
}

.playoff-table .playoff-table-pair-winner:before {
    content: '';
    position: absolute;
    top: 27px;
    right: -12px;
    width: 12px;
    height: 1px;
    /* background-color: red; */
}

.playoff-table .playoff-table-pair-winner:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 1000px;
    background-color: #f5f5f5;
    right: -12px;
    z-index: 1;
}

.playoff-table .playoff-table-pair-winner:nth-child(even):after {
    top: 28px;
}

.playoff-table .playoff-table-pair-winner:nth-child(odd):after {
    bottom: 28px;
}

.playoff-table .playoff-table-pair-style {
    border: 1px solid #cccccc;
    background-color: white;
    width: 160px;
    margin-bottom: 20px;
}

.playoff-table .playoff-table-group-winner {
    padding-right: 11px;
    padding-left: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: -ms-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    flex-direction: column;
    -ms-justify-content: space-around;
    justify-content: space-around;
}

.playoff-table .playoff-table-group-winner .playoff-table-pair-style:last-child {
    margin-bottom: 0px;
}

.playoff-table .playoff-table-group-winner:after {
    content: '';
    position: absolute;
    top: 28px;
    bottom: 29px;
    right: 0px;
    width: 1px;
    /* background-color: red; */
}

.playoff-table .playoff-table-group-winner:last-child {
    margin-bottom: 0;
}

.playoff-table .playoff-table-left-player-winner,
.playoff-table .playoff-table-right-player {
    min-height: 26px;
    padding: 3px 5px;
}

.playoff-table .playoff-table-left-player-winner {
    border-bottom: 1px solid #cccccc;
}

.playoff-table .playoff-table-left-player-winner:before {
    content: '';
    position: absolute;
    bottom: 27px;
    left: -12px;
    width: 12px;
    height: 1px;
    /* background-color: red; */
}

.playoff-table .playoff-table-right-player {
    margin-top: -1px;
    border-top: 1px solid #cccccc;
}

.playoff-table .playoff-table-third-place {
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 100px;
}

.playoff-table .playoff-table-third-place .playoff-table-left-player-winner:before {
    display: none;
}

.playoff-table .playoff-table-tour:first-child .playoff-table-group-winner {
    padding-left: 0;
}

.playoff-table .playoff-table-tour:first-child .playoff-table-left-player-winner:before {
    display: none;
}

.playoff-table .playoff-table-tour:last-child .playoff-table-group-winner:after {
    display: none;
}

.playoff-table .playoff-table-tour:last-child .playoff-table-pair-winner:after,
.playoff-table .playoff-table-tour:last-child .playoff-table-pair-winner:before {
    display: none;
}

/* winner */
/*# sourceMappingURL=playoff-table.css.map */

.helper-main {
    background-color: rgb(0, 0, 0);
}

.card {
    background-color: #ffffff;
    border: 1px solid #e0e0ee;
    width: 35%;
    padding: 40px 30px 20px;
    border-radius: 5px;
    transition: all 500ms ease;
    /* margin: 50px 0 10px; */
}

.card-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.heading {
    font-size: 24px;
    font-weight: bold;
}

.break-line {
    border-top: 0.5px solid #0000003a;
    margin: 0 -30px;
}

.setting-heading {
    font-size: 20px;
    font-weight: 900;
    margin-top: 10px;
    margin-bottom: 5px;
}

.head-center {
    text-align: center;
}

.form-input {
    border-radius: 4px !important;
}

.form-button {
    border-radius: 4px !important;
    /* background-color: #4bb146 !important; */
    color: #ffffff !important;
    /* border: 1px solid #4bb146; */
}

.menu-flex {
    display: flex;
    flex-direction: row;
}

.helper-comp {
    position: relative;
    width: 100%;
}

.home-main {
    height: calc(100vh - 60px);
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.form-button:hover {
    border-color: #4bb146 !important;
}

.linkStyle {
    color: white;
    cursor: pointer;
    font-weight: bolder;
}

.linkStyle :hover {
    color: white !important;
    cursor: pointer !important
}


.auth-screen-main-div {
    width: 80%;
    padding: 30px;
    margin: 10px auto;
    /* background: #FBFBFB; */
}

.form-two-input-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
}

.form-two-input-flex-inner-input {
    width: 49%;
    padding: 5px;
    margin-top: 5px;
    border-radius: 4px;
}

.form-two-input-flex-inner-input .ant-input-number-affix-wrapper {
    -webkit-padding-start: 0px !important;
}

.form-two-input-flex-inner-input .ant-input-number-affix-wrapper input.ant-input-number-input {
    padding: 10px !important;
    height: 100% !important;
}


.form-two-input-flex-inner-input .ant-upload span button {
    width: 100% !important;
    height: 60px !important;
    border-radius: 6px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
    border: none !important;
}

.form-two-input-flex-inner-input .ant-upload span button:hover {
    width: 100% !important;
    height: 60px !important;
    border-radius: 6px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
    border: none !important;
    color: black !important;
}

.form-two-input-flex-inner-input .ant-upload span button:focus {
    color: black !important;
    width: 100% !important;
    height: 60px !important;
    border-radius: 6px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
    border: none !important;
}


.form-two-input-flex-inner-input .ant-upload {
    width: 100% !important;
    height: 65px !important;
}

.form-input-new-style {
    border-radius: 6px !important;
    height: 55px !important;
    border: none !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 2.5px 8px;
}


.form-input-new-style:focus {
    border: none !important;
}

.form-input-new-style.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px !important;
    border: none !important;
    padding: 6px !important;
    height: 100% !important;
    padding: 12px !important;
}

.form-input-new-style.ant-select:not(.ant-select-customize-input) .ant-select-selector :focus {
    border: none !important;
}

.form-input-new-style .ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border: none !important;
    height: 60px !important;
}

.form-input-new-style .ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) :hover {
    border: none !important;
    height: 60px !important;
}

.form-input-new-style .ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) :focus {
    border: none !important;
    height: 60px !important;
}

.newInpuHeightFull .ant-input-number-input-wrap {
    height: 100% !important;
}

.newInpuHeightFull-withMargin .ant-input-number-input-wrap {
    height: 100% !important;
    padding-top: 10px !important;
}



.form-input-new-style-sufix .ant-input-group {
    height: 56px !important;
}

.form-input-new-style-sufix .ant-input-group-addon {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border: none !important;
    border-left: 1px solid rgb(0 0 0 / 24%) !important;

}

.form-input-new-style-sufix .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    height: 56px !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    background-color: white !important;
    border: none !important;
}

.update-btn-text {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #ec671b !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.addproduct-text {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #ec671b !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.addproduct-text:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #e39161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.addproduct-text:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #e39161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawalbtn {
    font-size: 14px !important;
    line-height: 1.4 !important;
    /* cursor: pointer !important; */
    background-color: #ff0000 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawalbtn:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    /* cursor: pointer !important; */
    background-color: #e36161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawalbtn:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    /* cursor: pointer !important; */
    background-color: #e36161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawaltimeput {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #E36161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawaltimeput:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #E36161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawaltimeput:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #E36161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.accept_m_btn {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #00da99 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.accept_m_btn:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #00da99 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.accept_m_btn:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #00da99 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.dissabled-sub {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #ff9854 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.dissabled-sub:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #ff9854 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.dissabled-sub:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #ff9854 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.i-won-button {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #0a8316 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.i-won-button:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #0e981c !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.i-won-button:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #0e981c !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.i-won-button-dis {
    font-size: 14px !important;
    line-height: 1.4 !important;
    background-color: #0a8316 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}


.input-none div span input {
    height: 55px !important;
}

.screen-heading {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.4;
    cursor: pointer;
    color: #ec671b;
}

.div-flex-back-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.screen-back-icion {
    height: 35px;
    margin-right: 14px;
    cursor: pointer
}

.height-35 {
    height: 35px;
}

.footer-text {
    color: white;
    cursor: pointer;
    margin: 0px;

}

.countries-select-span {
    margin-top: 10px !important;
    padding-bottom: 9px;
}

.countries-select>button {
    border: none !important;
    background-color: white;
    width: 60px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.countries-select-grey>button {
    border: none !important;
    background-color: rgb(107, 107, 107);
    width: 60px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.countries-select-update-span {
    margin-top: 10px !important;
    padding-bottom: 9px;
}

.countries-select-update>button {
    border: none !important;
    background-color: white;
    width: 60px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.verify-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.verify-text {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #ec671b;
    ;
}

.verify-head {
    margin-bottom: -10px;
    text-align: center;
    font-size: 80px;
    font-weight: 600;
    color: #ec671b;
    ;
}

.verify-div {
    height: 100vh;
    background-image: linear-gradient(180deg, #dfedf0, #e6dada);
}

/* scroll  */
.sidebar-scroll_class::-webkit-scrollbar {
    width: 4.5px !important;
    height: 3.5px !important;
    border-radius: 4px !important;
    background-color: rgba(76, 175, 80, 0) !important;
}

.sidebar-scroll_class::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    height: 3.5px !important;
    background-color: transparent;
}

.sidebar-scroll_class::-webkit-scrollbar-thumb {
    /* background: var(--color3) !important; */
    background: #ec671b !important;
    border-radius: 10px !important;
    height: 2px !important;
    /* display: none !important; */
}

/* scroll  rgb(92, 93, 93)*/
.sidebar-scroll-class-black::-webkit-scrollbar {
    width: 4.5px !important;
    height: 3.5px !important;
    border-radius: 4px !important;
    background-color: rgba(55, 184, 60, 0) !important;
}

.sidebar-scroll-class-black::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    height: 3.5px !important;
    background-color: transparent !important;
}

.sidebar-scroll-class-black::-webkit-scrollbar-thumb {
    background: rgb(92, 93, 93) !important;
    border-radius: 10px !important;
    height: 2px !important;
}


/* side menu  */

.home-main {
    height: 100%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: white;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



/* ////////////////////////////////////////////////NEWNEWNEWNWENWENWENWENWNEWNE */

.new-side-menu.ant-layout-sider-has-trigger {
    padding: 0px !important;
    background-color: white;
}

.new-side-menu>.ant-layout-sider-trigger {
    background-color: #ec671b !important;
    display: none !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
    color: #ec671b !important;
    border-radius: 10px !important;
    border-right: 6px solid #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
    background-color: transparent !important;
    color: #ec671b !important;
    border-radius: 10px !important;
    border-right: 6px solid #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item a:hover {
    color: #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a,
.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover {
    color: #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu:hover {
    color: #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-arrow {
    color: #ec671b !important;
}


.my-menu-sh-new .ant-menu-submenu-title:hover {
    color: #ec671b !important;
}

.my-menu-sh-new .ant-menu-item-selected .ant-menu-title-content a {
    background-color: transparent !important;
    color: #ec671b !important;
    border-radius: 10px !important;
    /* background-color: white !important; */
}

.my-menu-sh-new .ant-menu-item-selected {
    background-color: transparent !important;
    color: #ec671b !important;
    border-radius: 10px !important;
    background-color: white !important;
    border-right: 6px solid #ec671b !important;
}

.my-menu-sh-new .ant-menu-submenu-selected {
    background-color: transparent !important;
    color: #ec671b !important;
    border-radius: 10px !important;
    background-color: white !important;
}

.side-list {
    font-size: 18px;
    transition: 0.3s !important;
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
    border: none !important;
}

/* side menu  */
.admin-home-title {
    font-size: 24px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #ec671b;
    font-weight: 600;
    margin: 0px;
    line-height: 1.4;
}

.admin-home-title-white {
    font-size: 26px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #ffffff;
    font-weight: 600;
    margin: 0px;
    margin-left: 1%;
    line-height: 1.4;
    text-shadow: 3px 4px 7px rgba(0, 0, 0, 0.8);
}

.desktop-head {
    display: flex;
}

.mobile-head {
    display: none;
}

.button-div-flex-end {
    height: 40px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.footer-img {
    display: none !important;
}

.p_button_style {
    border: 2px solid rgb(236, 103, 27);
    color: rgb(236, 103, 27);
    padding: 2.5px;
    width: 80px;
    text-align: center;
    border-radius: 8px;
}

/*  */




.form-input-new-style-select-minhi {
    border-radius: 6px !important;
    min-height: 55px !important;
    border: none !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 2.5px 8px;
}

.form-input-new-style-select-minhi:focus {
    border: none !important;
}

.form-input-new-style-select-minhi.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px !important;
    border: none !important;
    padding: 6px !important;
    height: 100% !important;
    padding: 12px !important;
}

.form-input-new-style-select-minhi.ant-select:not(.ant-select-customize-input) .ant-select-selector :focus {
    border: none !important;
}

.p-input-style {
    padding: 3px;
    width: 100px;
    height: 29px;
    margin: 0px;
    padding-left: 6px;
    border: 1px solid #EC671B;
    border-radius: 3px;
    cursor: pointer;
}

.accept-status {
    padding: 1px 14px;
    color: green;
    background: #4bff0047;
    margin: 0px;
    border-radius: 20px;
    text-align: center;
}

.draw-status {
    padding: 1px 14px;
    color: rgb(255, 255, 0);
    background: rgb(91 91 0);
    margin: 0px;
    border-radius: 20px;
    text-align: center;
}

.reject-status {
    text-align: center;
    padding: 1px 14px;
    margin: 0px;
    background: #ff00003b;
    color: red;
    border-radius: 20px;
}

.pending-stats {
    text-align: center;
    margin: 0px;
    padding: 1px 14px;
    color: #9100ff;
    background: #cc00ff26;
    border-radius: 20px;
}

.table_p {
    margin: 0px;
}

.promoter-form {
    width: 900px
}

.p-none-margin {
    margin: 0px;
    display: flex;
}

.p-none-margin-bolder {
    margin: 0px;
    font-weight: bolder;
}

.border-line {
    border-bottom: 2px dotted #ec671b;
    margin-top: 10px;
    margin-bottom: 10px
}

.p-none-margin-bolder-p {
    display: flex;
    justify-content: center;
    margin: 0px;
    font-weight: bolder;
    margin-top: 10px;
    color: #ec671b;
}

.same-button-not-allowed {
    height: 45px;
    width: 180px;
    margin-top: 10px;
    border: none;
    color: white;
    background-color: rgb(236, 103, 27);
    border-radius: 4px;
    cursor: not-allowed;
}

.custom-nodata-p {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    color: gray;
    font-size: 16px;
}

.reward-point-box {
    box-shadow: 0 0px 6px 3px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 16px;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    background-color: white;
    /* style={{
        // backgroundColor: '#f1f1f1',
    }} */
}

.ant-switch-checked {
    background-color: #ec671b !important;
}

.p-question {
    font-size: 20px;
    margin-bottom: 2px;
    text-align: center;
    background: #ec671b33;
    color: #ec671b;
    padding: 20px 0px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.p-opt {
    margin: 0px;
    font-size: 15px;
    margin-left: 4px;
    font-weight: bold;
}

.pop-info-modal .ant-modal-content {
    border-radius: 20px !important;
}

.pop-info-modal .ant-modal-close-x {
    display: none !important;

}

.pop-info-modal .ant-modal-body {
    padding: 14px !important;

}

.cancel-btn {
    width: 50%;
    text-align: center;
    border: none;
    background-color: white;
    color: #ec671b;
    height: 40px;
    border: 1px solid #ec671b;
    cursor: pointer;
}

.confirm-btn {
    cursor: pointer;
    width: 50%;
    text-align: center;
    border: none;
    color: white;
    background-color: #ec671b;
    height: 40px;
}

.p-promoted {
    background: #ec671b4a;
    padding: 2px 10px;
    color: #ec671b;
    border-radius: 15px;
    width: 110px;
    text-align: center;
}

.p-not-promoted {
    background: #d51bec52;
    padding: 2px 10px;
    color: #d51bec;
    border-radius: 15px;
    width: 110px;
    text-align: center;
}


.subscription-title-new {
    font-size: 22px;
    font-weight: 500;
    transition: 1s;
    /* border-bottom: 1px solid rgba(100, 100, 100, 0.3); */
}

.modal_width {
    min-width: 70% !important;
}

.border-bottom-dotted {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 2px dotted #ec671b
}

.input12345 .ant-input-number {
    height: 43px;
    padding-top: 12px;
    padding-left: 6px;
    box-shadow: none;
}

/* /////////// */
.ranking-main-div {
    background-color: black;
    background-image: url(/static/media/Rpoint.9d2b2455.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.pro-ranking-main-div {
    background-color: black;
    background-image: url(/static/media/PrPoint.e8801669.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.create-match-req-main-div {
    background-color: black;
    background-image: url(/static/media/create-match.777a9d95.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.match-req-main-div {
    background-color: black;
    background-image: url(/static/media/PaddelMatchRequest.0583f989.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.home-main-div {
    background-color: black;
    background-image: url(/static/media/PaddelHomeMain.f71c1a43.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.match-result-main-div {
    background-color: black;
    background-image: url(/static/media/MatchResult.4bb2894c.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.tournament-match-result-main-div {
    background-color: black;
    background-image: url(/static/media/tournament-result.bda8f532.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}



.tournamnent-select-main-div {
    background-color: black;
    background-image: url(/static/media/tournamentSelect.2dcab913.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.tournamnent-info-main-div {
    background-color: black;
    background-image: url(/static/media/tournamentInfo.66c74056.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.create-tournament-main-div {
    background-color: black;
    background-image: url(/static/media/crt-tournament.5431cd41.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.subscription-main-div {
    background-color: black;
    background-image: url(/static/media/subscription-new.d7df1974.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.profile-main-div {
    background-color: black;
    background-image: url(/static/media/PaddelMatchRequest.0583f989.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.claim-p-main-div {
    background-color: black;
    background-image: url(/static/media/claim.af0fb332.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.contact-p-main-div {
    background-color: black;
    background-image: url(/static/media/contact.b6d571d6.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.game-p-main-div {
    background-color: black;
    background-image: url(/static/media/new-game.57f05c39.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.game-sugg-p-main-div {
    background-color: black;
    background-image: url(/static/media/game-sugg.5b7c7234.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.invite-friend-p-main-div {
    background-color: black;
    background-image: url(/static/media/invtFriend.5b213a31.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.cash-back-p-main-div {
    background-color: black;
    background-image: url(/static/media/c-back.8f0f92e7.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.wallet-p-main-div {
    background-color: black;
    background-image: url(/static/media/wl-tr.ac92eab6.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.faq-all-main-div {
    background-color: black;
    background-image: url(/static/media/faq.5446ea79.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}



/* AUTH SCREEN  */

.login-main-screen {
    background-color: black;
    background-image: url(/static/media/login-new.fb8875f0.jpeg);
    background-size: 100% auto;
    background-repeat: repeat;
    height: calc(100%);
    padding: 2px;
}

.signup-main-screen {
    background-color: black;
    background-image: url(/static/media/signup-new.8f0f92e7.jpeg);
    background-size: 100%;
    background-repeat: repeat;
    height: calc(100%);
    padding: 2px;
}

.forgot-password-main {
    background-color: black;
    background-image: url(/static/media/forgot-new.0b460ca4.jpeg);
    background-size: 100%;
    background-repeat: repeat;
    height: calc(100%);
    padding: 2px;
}

.admin-login-main {
    background-color: black;
    background-image: url(/static/media/admin-login.1269f204.jpg);
    background-size: 100%;
    background-repeat: repeat;
    height: calc(100%);
    padding: 2px;
}

.Player-Home-court-main-div {
    background-color: black;
    background-image: url(/static/media/paddelHome.1f47457d.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.player-privat-court-main-div {
    background-color: black;
    background-image: url(/static/media/paddelPrivateCourt.8845bb3e.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.paddel_create-match-req-main-div {
    background-color: black;
    background-image: url(/static/media/PaddelCreateMatch.b4b72d5e.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.club-peddel-p-main-div {
    background-color: black;
    background-image: url(/static/media/paddal-club-court.97e061b9.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

/* AUTH SCREEN  */


/* .custom-table-head-title {
    color: white;
    font-size: 18px;
    font-weight: bold;
    width: 200px;
    background-color: red;
}

.custom-table-head {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
} */

.tableValue {
    margin: 0px;
    width: 19%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    text-shadow: 3px 4px 7px rgba(81, 67, 21, 0.8);
}

.my-form-label {
    color: white;
    margin: 0px;
    font-size: 16;
    font-weight: bold;
    text-shadow: 3px 4px 7px rgba(81, 67, 21, 0.8);
}

.white-label {
    color: white;
}

.table-p {
    width: 250px;
    margin-left: 10px;
    margin-right: 10px
}


.new-btton {
    padding: 0px 20px 0px 20px;
    background: rgba(92, 93, 93, 1);
    color: white;
    border: none;
    height: 45px;
    margin-top: 10px;
    border-radius: 6
}

.p-admin-normal-text {
    color: #ec671b;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
}

.p-admin-title-text {
    margin: 0px;
    color: #ec671b;
    font-size: 15px;
    font-weight: bolder;
}

.input-placeholder-orange input::placeholder {
    color: #ec671b;
}

.input-placeholder-orange input {
    color: #ec671b;
}

.select-placeholder-orange .ant-select-selector .ant-select-selection-placeholder {
    color: #ec671b;
}

.select-placeholder-orange .ant-select-selector .ant-select {
    color: #ec671b;
}

.date-placeholder-orange .ant-picker-input input::placeholder {
    color: #ec671b;
}

.time-placeholder-orange .ant-picker-input input::placeholder {
    color: #ec671b;
}

.font-math {
    font-family: Arial, Helvetica, sans-serif;
    word-spacing: -2px;
}

.login-s-button {
    padding-left: 22px;
    padding-right: 22px;
    height: 39px;
    border-radius: 6px;
    border: none;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
}

.home-1st-div {
    border: 2px solid white;
    height: 250px;
    width: 95%;
    margin: 50px auto 0px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
}

.txt-div-home {
    margin-top: 30px;
    margin-left: 20px;
}

.home-soldier-img {
    margin-top: -120px;
}

.home-button-div {
    margin-top: 20px;
}

.ppp-box {
    background: #ffffff59;
    padding: 10px;
    border-radius: 11px;
    margin-top: 10px;
    box-shadow: 2px 2px 2px white;
}

.main-title-result {
    color: white;
    font-size: 22px;
    cursor: pointer;
    margin-left: 4px;
}

/* .textarea-placeholder-orange {
    color: #ec671b;
} */
/* NEW STYLING  */
.main-div-new-style {
    margin: 30px;
    margin-bottom: 0px;
    min-height: calc(100% - 60px);
}



.div-flex-back-res {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 9px
}

.screen-heading {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.4;
    cursor: pointer;
    color: #ec671b;
}

.screen-back-icion {
    height: 35px;
    margin-right: 14px;
    cursor: pointer
}

.addproduct-text {
    font-size: 14px;
    line-height: 1.4;
    cursor: pointer;
    background-color: #ec671b;
    color: white;
    padding: 5px;
    height: 30px;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    border: none !important;
}

.addproduct-text:hover {
    font-size: 14px;
    line-height: 1.4;
    cursor: pointer;
    background-color: #e39161;
    color: white;
    padding: 5px;
    height: 30px;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    border: none !important;
}

.addproduct-text:focus {
    font-size: 14px;
    line-height: 1.4;
    cursor: pointer;
    color: #e39161;
    background-color: white;
    padding: 5px;
    height: 30px;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    border: none !important;
}

.cancel-btn {
    font-size: 14px;
    line-height: 1.4;
    cursor: pointer;
    color: #ec671b;
    background-color: white;
    padding: 5px;
    height: 30px;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid #ec671b;
}

.cancel-btn:hover {
    font-size: 14px;
    line-height: 1.4;
    cursor: pointer;
    color: #e39161;
    background-color: white;
    padding: 5px;
    height: 30px;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid #ec671b;
}

.cancel-btn:focus {
    font-size: 14px;
    line-height: 1.4;
    cursor: pointer;
    background-color: #e39161;
    color: white;
    padding: 5px;
    height: 30px;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid #ec671b;
}

.height-35 {
    height: 35px;
}

.new-input-search {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.new-input-search>.ant-input-wrapper {
    border: none !important;
    height: 45px !important;
}

.new-input-search>.ant-input-wrapper.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
    border: none !important;
    height: 45px !important;
}

.new-input-search>.ant-input-wrapper.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child:focus {
    border: none !important;
}

.new-input-search .ant-btn-primary {
    background-color: #ec671b !important;
    border: none !important;
    height: 45px !important;
    width: 55px !important;
}

.new-filter-btn {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-left: 8px;
    width: 80px;
    display: flex;
    flex-direction: row;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    align-items: center
}

.new-filter-btn-text {
    line-height: 1;
    margin: 8px;
    margin-top: 6px;

}

.new-delete-Btn {
    background-color: #ec671b;
    color: white;
    width: 49%;
    border: 1px solid #ec671b;
    border-radius: 4px;
}

.new-edit-Btn {
    background-color: white;
    color: #ec671b;
    width: 49%;
    border: 1px solid #ec671b;
    border-radius: 4px;
}

.new-delete-Btn:hover {
    background-color: #e39161;
    color: white;
    width: 49%;
    border: 1px solid #e39161;
    border-radius: 4px;
}

.new-delete-Btn:focus {
    background-color: #e39161;
    color: white;
    width: 49%;
    border: 1px solid #e39161;
    border-radius: 4px;
}

.mobileDiv {
    display: none !important;
}

.laptopDiv {
    display: flex !important;
}

.guestHomeInfoHeading {
    font-weight: 400;
    font-style: normal;
    text-align: left;
    color: #ffffff;
}

.new-input-search-guest {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.new-input-search-guest>.ant-input-wrapper {
    border: none !important;
    height: 45px !important;
}

.new-input-search-guest>span .ant-input-group-addon {
    border: none !important;
    height: 45px !important;
    border-radius: 4px !important;
}

.new-input-search-guest>.ant-input-wrapper.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
    border: none !important;
    height: 45px !important;
    border-radius: 4px !important;
}

.new-input-search-guest>.ant-input-wrapper.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child:focus {
    border: none !important;
}

.new-input-search-guest .ant-btn-primary {
    background-color: #ec671b !important;
    border: none !important;
    height: 45px !important;
    width: 55px !important;
}

.cards-main-div-guest-all-crds {
    padding: 30px;
}

.GuestCardsTitle {
    font-size: 25px;
    font-weight: bold;
    font-style: normal;
    color: #ec671b;
}

.loading-card-style {
    height: 285px;
    width: 290px;
    min-width: 290px;
    border-radius: 4px;
    margin: 4px;
    margin-right: 10px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer
}

.guest-card-tect-string {
    font-weight: bold;
    width: 160px;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
}

.guest-card-review-box {
    margin-right: 5px;
    height: 40px;
    width: 35px;
    padding: 0px;
    background: #ec671b;
    border: none;
    font-size: 11px;
    font-weight: 500;
    color: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card_sold {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0;
    opacity: 0.8;
}

.card_sold_price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card_desc1 {
    font-size: 0.7em;
    font-weight: 600;
    margin-bottom: 0;
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: gray;
}

.review-text {
    padding: 0;
    font-size: 14;
    margin: 0;
    line-height: 1.2;
}

.review-text-aim-2 {
    display: none;
}

.progress-main-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10;
}

.progress-text-point-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.progress-inner-main-div {
    width: 48%;
}

.product_img {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
}

.ico-dis-play {
    margin-top: -24px;
    margin-left: -12px;
}

.buyProduct_imges_main_div {
    height: 350px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1%;
}

.buyProduct_2nd_img_div {
    width: 50%;
    padding: 4px;
    height: 350px
}

.buyProduct_2nd_img_div_inner_flex {
    display: flex;
    flex-direction: column
}

.img_div_inner_flex_1st {
    display: flex;
    flex-direction: row;
    height: 170px;
    justify-content: space-between;
}

.img_div_img {
    width: 49%;
    height: 99%;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    border-radius: 4px;
}

.img_div_inner_flex_2nd {
    width: 100%;
    height: 167px;
    margin-top: 4px;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    border-radius: 4px;
}

.admin-review-div {

    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.review-text {
    padding: 0;
    font-size: 14;
    margin: 0;
    line-height: 1.2;
}

.white-p {
    color: white;
}

/* //////////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////////// */
@media (max-width: 900px) {

    .buyProduct_imges_main_div,
    .buyProduct_1st_img_div,
    .buyProduct_2nd_img_div {
        height: 300px;
    }

    .buyProduct_1st_img {
        height: 290px;
    }

    .img_div_inner_flex_2nd {
        height: 145px;

    }

    .img_div_inner_flex_1st {
        height: 142px;

    }
}

@media (max-width: 450px) {
    .progress-main-div {
        display: flex;
        flex-direction: column;
    }

    .progress-inner-main-div {
        width: 100%;
    }

    .invite-guest {
        margin-right: 0px;
    }
}

@media (max-width: 600px) {
    .review-text-aim {
        display: none;
    }

    .review-text-aim-2 {
        display: flex;
    }
}

@media (max-width: 750px) {


    .cards-main-div-guest-all-crds {
        padding: 10px;
    }

}

@media (max-width: 700px) {

    .button-flex-box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }


    .MobileTable {
        display: flex !important;
    }

    .divFaqDiv {
        /* width: '80%', margin: '10px auto', marginTop: '30px' */
        width: 100%;
        margin: 10px auto;
        margin-top: 30px;
    }

    .buyProduct_imges_main_div {
        height: auto !important;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        width: 98%;
    }

    .buyProduct_1st_img_div {
        width: 100%;
        padding: 4px;
        height: 350px;
    }

    .buyProduct_2nd_img_div {
        width: 100%;
        padding: 4px;
        height: 350px
    }

    .main-div-new-style {
        padding: 10px;
        margin-bottom: 0px;
    }

    .laptopDiv {
        display: none !important;
    }

    .mobileDiv {
        display: flex !important;
    }

    .new-side-menu>.ant-layout-sider-trigger {
        display: none !important;
    }

    .sideMenuHeader {
        background: #ec671b;
    }

    .sideMenuHeader-SidebarLogo {
        display: none;
    }

    .sideMenuHeader div .backIconHeader {
        display: flex;
        cursor: pointer;
    }

    .sideMenuHeader div {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
    }

    .sideMenuHeader div p {
        color: white;
        font-weight: 200;
        font-family: 'fantasy';
        margin: 0px;
        display: block;
    }
}

@media (max-width: 560px) {
    .new-setting-div {
        flex-direction: column;
    }

    .setting-div-cards {
        width: 95%;
    }

    .div-flex-back-res {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .main-div-new-style {
        margin: 10px;
        margin-bottom: 0px;
    }

    .addproduct-text {
        width: 120px;
    }

}

/* ///////////////////////// */
@media (min-width: 1500px) {
    .helper-main {
        width: 1450px;
        justify-content: center;
        margin: 0px auto;
        background-color: rgb(0, 0, 0);
    }
}

@media(max-width: 1200px) {
    .card {
        width: 45%;
    }
}

@media(max-width: 1000px) {
    .card {
        width: 60%;
    }
}

@media(max-width: 950px) {
    .promoter-form {
        width: 95%
    }
}

@media(max-width: 900px) {
    .card {
        width: 65%;
    }
}

@media (max-width: 1070px) {
    .home-1st-div {
        height: auto;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column-reverse;
    }

    .home-img-div {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 460px) {
    .margin-div {
        height: 50px;
    }
}

@media (max-width: 630px) {
    .home-soldier-img {
        width: 450px;
        margin-top: -80px;
    }
}

@media (max-width: 460px) {
    .home-soldier-img {
        width: 350px;
    }

    .playersFootbal-img {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .auth-screen-main-div {
        padding: 30px;
        width: 100%;
        margin: 10px auto;
        /* background: #FBFBFB; */
    }

}

@media(max-width: 800px) {
    .card {
        width: 70%;
    }
}


@media(max-width: 700px) {
    .card {
        width: 75%;
    }

    .desktop-head {
        display: none;
    }

    .mobile-head {
        display: flex;
    }

    .footer-img {
        display: flex !important;
    }

    .form-two-input-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .form-two-input-flex-inner-input {
        width: 100%;
    }
}

@media (max-width: 750px) {
    .login-main-screen {
        background-image: url(/static/media/login-new-mobile.754fd1cf.jpeg);
    }

    .signup-main-screen {
        background-image: url(/static/media/signup-new-mobile.50e6d651.jpeg);
    }

    .forgot-password-main {
        background-image: url(/static/media/forgot-new-mobile.83e04a70.jpeg);
    }

    .admin-login-main {
        background-image: url(/static/media/admin-login-mobile.f54329f5.jpg);
    }
}


@media (max-width: 950px) {
    .tournamnent-select-main-div {
        background-image: url(/static/media/tournamentSelect-M.a3f87abe.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .create-match-req-main-div {
        background-image: url(/static/media/create-match-mobile.bcbda67f.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .match-req-main-div {
        background-image: url(/static/media/PaddelMatchRequestMobile.5f3df0f4.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .match-result-main-div {
        background-image: url(/static/media/MatchResult-mobile.e624306f.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .tournament-match-result-main-div {
        background-image: url(/static/media/tournament-result-mobile.bf04ec52.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .subscription-main-div {
        background-image: url(/static/media/Subscription-new-mobile.cf02d59e.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .contact-p-main-div {
        background-image: url(/static/media/contact-mobile.8c5e7d1f.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .faq-all-main-div {
        background-image: url(/static/media/faq-mobile.7ef27694.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .profile-main-div {
        background-image: url(/static/media/PaddelMatchRequestMobile.5f3df0f4.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .ranking-main-div {
        background-image: url(/static/media/Rpoint-mobile.75b1f39a.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .pro-ranking-main-div {
        background-image: url(/static/media/PrPoint-mobile.fa1fae36.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .claim-p-main-div {
        background-image: url(/static/media/Claim-mobile.a6c676a1.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .game-p-main-div {
        background-image: url(/static/media/new-game-mobile.c743e65b.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .game-sugg-p-main-div {
        background-image: url(/static/media/game-sugg-mobile.a6254cfd.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .invite-friend-p-main-div {
        background-image: url(/static/media/invtFriend-mobile.42091fff.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .cash-back-p-main-div {
        background-image: url(/static/media/c-back-mobile.50e6d651.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .wallet-p-main-div {
        background-image: url(/static/media/wl-tr-mobile.eaad6c5e.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .home-main-div {
        background-image: url(/static/media/PaddelHomeMainMobile.65755588.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .tournamnent-info-main-div {
        background-image: url(/static/media/forgot-new-mobile.83e04a70.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .Player-Home-court-main-div {
        background-image: url(/static/media/PaddelHomeMob.90b88a14.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .player-privat-court-main-div {
        background-image: url(/static/media/paddelPrivateCourtMobile.c7e17dbc.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .paddel_create-match-req-main-div {
        background-image: url(/static/media/PaddelCreateMatchMob.97a13345.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .club-peddel-p-main-div {
        background-image: url(/static/media/paddal-club-courtMob.311902f1.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

}


@media(max-width: 600px) {
    .card {
        width: 80%;
        padding: 20px 20px 10px;
    }

    .break-line {
        margin: 0 -20px;
    }

    .modal_width {
        min-width: 70% !important;
    }
}

@media (max-width: 560px) {
    .addproduct-text {
        width: 120px;
    }
}

@media(max-width: 500px) {
    .card {
        width: 85%;
    }

    .heading {
        font-size: 20px;
    }
}

@media(max-width: 400px) {
    .card {
        width: 90%;
    }
}

@media(max-width: 300px) {
    .card {
        width: 95%;
    }

    .heading {
        font-size: 18px;
    }
}
